<template>
  <v-container fluid>
        <!-- collaborate -->
      <div class="colorlib-intro">
            <h1>Datenschutzerkl&auml;rung</h1><br>
            <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
            <p>Jens Koch</p>
            <h2>Ihre Betroffenenrechte</h2>
            <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten k&ouml;nnen Sie jederzeit folgende Rechte aus&uuml;ben:</p>
            <ul>
              <li>Auskunft &uuml;ber Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
              <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
              <li>L&ouml;schung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
              <li>Einschr&auml;nkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht l&ouml;schen d&uuml;rfen (Art. 18 DSGVO),</li>
              <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
              <li>Daten&uuml;bertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
            </ul>
            <p>Sofern Sie uns eine Einwilligung erteilt haben, k&ouml;nnen Sie diese jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen.</p>
            <p>Sie k&ouml;nnen sich jederzeit mit einer Beschwerde an eine Aufsichtsbeh&ouml;rde wenden, z. B. an die zust&auml;ndige Aufsichtsbeh&ouml;rde des Bundeslands Ihres Wohnsitzes oder an die f&uuml;r uns als verantwortliche Stelle zust&auml;ndige Beh&ouml;rde.</p>
            <p>Eine Liste der Aufsichtsbeh&ouml;rden (f&uuml;r den nicht&ouml;ffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
            <h2>Kontaktformular</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierf&uuml;r ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschlie&szlig;enden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
            <h3>Rechtsgrundlage:</h3>
            <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art 6 Abs. 1 lit. f DSGVO).</p>
            <p>Durch Bereitstellung des Kontaktformulars m&ouml;chten wir Ihnen eine unkomplizierte Kontaktaufnahme erm&ouml;glichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie f&uuml;r m&ouml;gliche Anschlussfragen gespeichert.</p>
            <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen (Art 6 Abs. 1 lit. b DSGVO).</p>
            <h3>Empf&auml;nger:</h3>
            <p>Empf&auml;nger der Daten sind ggf. Auftragsverarbeiter.</p>
            <h3>Speicherdauer:</h3>
            <p>Daten werden sp&auml;testens 6 Monate nach Bearbeitung der Anfrage gel&ouml;scht.</p>
            <p>Sofern es zu einem Vertragsverh&auml;ltnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und l&ouml;schen Ihre Daten nach Ablauf dieser Fristen. </p>
            <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir k&ouml;nnen Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
            <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <p>Um unsere Inhalte browser&uuml;bergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</p>
            <p>Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>
            <h3>Rechtsgrundlage:</h3>
            <p>Rechtsgrundlage f&uuml;r die Einbindung von Google Webfonts und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). </p>
            <h3>Empf&auml;nger:</h3>
            <p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken l&ouml;st automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch m&ouml;glich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass der Betreiber in diesem Fall Google Daten erhebt.</p>
            <h3>Speicherdauer:</h3>
            <p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Webfonts.</p>
            <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerkl&auml;rung von Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</p>
            <h3>Drittlandtransfer:</h3>
            <p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
            <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>Die Bereitstellung der personenbezogenen Daten ist weder gesetzlich, noch vertraglich vorgeschrieben. Allerdings kann ohne die korrekte Darstellung der Inhalte von Standardschriften nicht erm&ouml;glicht werden.</p>
            <h3>Widerruf der Einwilligung:</h3>
            <p>Zur Darstellung der Inhalte wird regelm&auml;&szlig;ig die Programmiersprache JavaScript verwendet. Sie k&ouml;nnen der Datenverarbeitung daher widersprechen, indem Sie die Ausf&uuml;hrung von JavaScript in Ihrem Browser deaktivieren oder einen Einbindung JavaScript-Blocker installieren. Bitte beachten Sie, dass es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen kann.</p>
            <h2>Verwendung von Google Maps</h2>
            <h3>Art und Zweck der Verarbeitung:</h3>
            <p>Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google Maps wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend „Google“) betrieben. Dadurch k&ouml;nnen wir Ihnen interaktive Karten direkt in der Webseite anzeigen und erm&ouml;glichen Ihnen die komfortable Nutzung der Karten-Funktion.</p>
            <p>N&auml;here Informationen &uuml;ber die Datenverarbeitung durch Google k&ouml;nnen Sie <a href="http://www.google.com/privacypolicy.html">den Google-Datenschutzhinweisen</a> entnehmen. Dort k&ouml;nnen Sie im Datenschutzcenter auch Ihre pers&ouml;nlichen Datenschutz-Einstellungen ver&auml;ndern.</p>
            <p>Ausf&uuml;hrliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten<a href="http://www.dataliberation.org/"> finden Sie hier</a>.</p>
            <h3>Rechtsgrundlage:</h3>
            <p>Rechtsgrundlage f&uuml;r die Einbindung von Google Maps und dem damit verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).</p>
            <h3>Empf&auml;nger:</h3>
            <p>Durch den Besuch der Webseite erh&auml;lt Google Informationen, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabh&auml;ngig davon, ob Google ein Nutzerkonto bereitstellt, &uuml;ber das Sie eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet.</p>
            <p>Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht w&uuml;nschen, m&uuml;ssen Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie f&uuml;r Zwecke der Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (selbst f&uuml;r nicht eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks &uuml;ber Ihre Aktivit&auml;ten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Aus&uuml;bung dessen an Google richten m&uuml;ssen.</p>
            <h3>Speicherdauer:</h3>
            <p>Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google Maps.</p>
            <h3>Drittlandtransfer:</h3>
            <p>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy Shield unterworfen <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>
            <h3>Widerruf der Einwilligung:</h3>
            <p>Wenn Sie nicht m&ouml;chten, dass Google &uuml;ber unseren Internetauftritt Daten &uuml;ber Sie erhebt, verarbeitet oder nutzt, k&ouml;nnen Sie in Ihrem Browsereinstellungen JavaScript deaktivieren. In diesem Fall k&ouml;nnen Sie unsere Webseite jedoch nicht oder nur eingeschr&auml;nkt nutzen.</p>
            <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
            <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden, kann es hierdurch zu Funktionseinschr&auml;nkungen auf der Website kommen.</p>
            <h2>&Auml;nderung unserer Datenschutzbestimmungen</h2>
            <p>Wir behalten uns vor, diese Datenschutzerkl&auml;rung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um &Auml;nderungen unserer Leistungen in der Datenschutzerkl&auml;rung umzusetzen, z.B. bei der Einf&uuml;hrung neuer Services. F&uuml;r Ihren erneuten Besuch gilt dann die neue Datenschutzerkl&auml;rung.</p>
            <h2>Fragen an den Datenschutzbeauftragten</h2>
            <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die f&uuml;r den Datenschutz verantwortliche Person in unserer Organisation:</p>
            <p>Jens Koch</p>
            <p><em>Die Datenschutzerkl&auml;rung wurde mit dem </em><em><a href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/" target="_blank" rel="noopener">Datenschutzerkl&auml;rungs-Generator der activeMind AG erstellt</a> (Version 2018-06-22).</em></p>

      </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
 
    };
  },
  
  created() {

  },

  methods: {

  },
  computed: {

  },
  mounted(){ }
};
</script>

<style lang="scss" scoped></style>